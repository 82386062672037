import React from 'react';
import { ArrowRight } from "lucide-react"
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
  } from "../ui/carousel";

interface Product {
  name: string;
  price: number;
  image: string;
  images: string[];
}

interface ProductProps {
    category?: string;
    products: Product[];
    isCarosel?: boolean;
    isCategory?:boolean;
    title?: string;
    isRowTwo?: boolean;
    actionText?: string;
  }
  

  
const Product: React.FC<ProductProps> = ({category, products, isCarosel, isCategory, title, isRowTwo, actionText}) => {
    return (
        <>
        {isRowTwo && <div className="px-8 mt-16">
            <div className='flex justify-between mt-16 mb-4'>
             <h2 className='font-semibold text-xl'>{category}</h2>
             <p className='flex text-[#F39909] cursor-pointer'>{actionText} <span><ArrowRight className='text-xs'/></span></p>
            </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-5 xl:gap-x-8">
                    {products.map((product) => (
                        <div  className="group relative p-1">
                            <div className="aspect-h-1 py-12 px-2 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                                <img
                                    alt={product.name}
                                    src={product.images[0]}
                                    className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                                />
                            </div>
                            <div className="flex justify-between">
                                <div>
                                    <h3 className="text-sm text-gray-700">
                                        {/* <a href={''}> */}
                                            <span aria-hidden="true" className="absolute inset-0" />
                                            {product.name}
                                        {/* </a> */}
                                    </h3>
                                </div>
                            </div>
                            <p className="text-sm font-medium text-gray-900">{product.price}</p>
                        </div>
                    ))} 
                </div>
        </div>}
        {isCarosel && 
         <>
          <h2 className='font-semibold  mt-16 text-xl'>{category}</h2>
           <Carousel className="w-full py-4">
                <CarouselContent className="ml-1 flex gap-4"> 
                    {products?.map((product) => (
                     <CarouselItem key={''} className="pl-1 md:basis-1/2 lg:basis-1/5 p-1 bg-[#F8F8F8] rounded-lg">
                        <div  className="group relative p-1">
                            <div className="aspect-h-1 py-12 aspect-w-1 w-full overflow-hidden rounded-md bg-[#F8F8F8] lg:aspect-none group-hover:opacity-75 lg:h-80">
                                <img
                                    alt={product.name}
                                    src={product.images[0]}
                                    className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                                />
                            </div>
                            <div className="flex justify-between">
                                <div>
                                    <h3 className="text-sm text-gray-700">
                                        <a href={''}>
                                            <span aria-hidden="true" className="absolute inset-0" />
                                            {product.name}
                                        </a>
                                    </h3>
                                    {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
                                </div>
                            </div>
                            <p className="text-sm font-medium text-gray-900">{product.price}</p>
                        </div>
                      </CarouselItem>
                    ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
           </Carousel>
         </>
          }
          {isCategory && 
          <>
          <h2 className='font-semibold  mt-16 text-xl'>{category}</h2>
            <div className=" w-full  flex my-8">
                {products.map((product) => (
                    <div className='lg:basis-1/8 bg-[#F8F8F8] rounded-full p-3 mx-4 flex justify-center items-center'>
                        <div className='rounded-full w-36 h-36 '>
                            <img
                            alt={''}
                            src={product.images[0]}
                            className=" object-cover object-center lg:h-full lg:w-full rounded-full bg-white"
                            />
                            <p>{title}</p>
                            
                        </div>
                    </div>
           ))}
            </div>
          </>
            }
        </>
    )
}

export default Product

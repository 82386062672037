export const productList = [
    {
        "richDescription": "The Hoover Compact Single-Door Upright Freezer is the perfect addition to any home, office, or dorm room, offering reliable and efficient storage for your frozen goods. This sleek, black freezer combines functionality with a modern design, ensuring it complements any space.\n\nKey Features:\n\nCompact Design: The freezer's compact size makes it ideal for small spaces, such as apartments, offices, or dorm rooms.\nSpacious Interior: Despite its compact exterior, the freezer boasts a generous interior space with four transparent drawers, allowing for easy organization and access to your frozen items.\nEnergy Efficient: Equipped with a high-efficiency compressor, this freezer ensures your food stays frozen while keeping energy consumption low.\nDurable Build: The robust build quality and scratch-resistant exterior ensure longevity, even in busy households or workplaces.\nEasy Maintenance: The removable drawers and simple interior design make cleaning a breeze, ensuring your freezer stays hygienic and odor-free.\nEnvironmentally Friendly: Uses R-600a refrigerant, which is eco-friendly and helps reduce your carbon footprint.\nTechnical Specifications:\n\nDimensions: 24.5 x 22 x 33 inches (H x W x D)\nColor: Black\nCapacity: 3.5 cubic feet\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\n",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/a586632c-76a4-400a-93e0-67645891c000__MG_7384.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/cb4e8442-9e06-401c-b6f3-4ec8f8d381fc__MG_7388.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/a2f4b308-f891-46a6-84b4-90ddcefa9edf__MG_7392.jpg"
        ],
        "brand": "Hoover",
        "price": 158250,
        "realPrice": 0,
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666aa30ca99dde00296b11fb",
        "rating": 2,
        "name": "Hoover Compact Single-Door Upright Freezer",
        "description": "Backed by Hoover’s reliable brand reputation, this compact upright freezer is an excellent choice for those in need of efficient and dependable freezing capabilities in a stylish, space-saving design.\n\n\n\n\n\n",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "666b0981aaaed6424d1ec13d",
        "dateCreated": "2024-06-13T07:43:08.524Z",
        "__v": 7,
        "id": "666aa30ca99dde00296b11fb"
    },
    {
        "richDescription": "Key Features:\n\nStylish Retro Design: The eye-catching red finish and chrome handles bring a nostalgic charm to your kitchen decor, making this refrigerator a stylish addition to any home.\nSpacious Storage: With a total capacity of 6.5 cubic feet, this refrigerator-freezer combo offers plenty of space to store your groceries. The top-mount freezer provides easy access to frozen foods, while the main refrigerator compartment includes adjustable shelves, door bins, and a spacious crisper drawer.\nEnergy Efficient: Designed with energy efficiency in mind, this refrigerator helps you save on electricity bills while keeping your food fresh and your frozen items well-preserved.\nDurable Construction: Built with high-quality materials, the Montpellier Retro Style Refrigerator-Freezer is made to last, ensuring reliable performance for years to come.\nEasy Maintenance: The removable shelves and door bins make cleaning and organizing a breeze, helping you keep your fridge in pristine condition with minimal effort.\nTechnical Specifications:\n\nDimensions: 65 x 24 x 27 inches (H x W x D)\nColor: Red\nCapacity: 6.5 cubic feet (4.5 cu. ft. refrigerator, 2.0 cu. ft. freezer)\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/9e23d2d5-1236-46fa-bd70-cccb82314200__MG_7396.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/a188a7ff-1b56-4169-9666-bf3b8b5d1143__MG_7399.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/c4c8f545-3faf-408c-abd9-72624ebc8430__MG_7401.jpg"
        ],
        "brand": "Montpellier",
        "price": 211000,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666aa45ca99dde00296b120f",
        "rating": 0,
        "name": "Montpellier Retro Style Top-Mount Refrigerator-Freezer - 6.5 Cu. Ft., Red",
        "description": "Add a touch of retro flair to your kitchen with the Montpellier Retro Style Top-Mount Refrigerator-Freezer. Combining vintage aesthetics with modern functionality, this vibrant red appliance not only makes a statement but also provides ample storage for your fresh and frozen foods.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "666b0981aaaed6424d1ec13d",
        "dateCreated": "2024-06-13T07:48:44.322Z",
        "__v": 0,
        "id": "666aa45ca99dde00296b120f"
    },
    {
        "richDescription": "Key Features:\n\nGenerous Capacity: Offering 7.0 cubic feet of total storage space, this refrigerator-freezer provides ample room for all your fresh and frozen groceries.\nTop-Mount Freezer: The convenient top-mount freezer allows for easy access to frozen foods and includes adjustable shelves to accommodate various items.\nEnergy Efficient: Designed to minimize energy consumption, this refrigerator helps you save on electricity bills while maintaining optimal cooling performance.\nDurable Construction: Built with high-quality materials, the Hoover Classic refrigerator is designed to withstand daily use, ensuring long-lasting reliability.\nEasy to Clean: The smooth surfaces and removable shelves and bins make cleaning this refrigerator a hassle-free task, helping you maintain a hygienic kitchen environment.\nSimple and Practical Design: The classic white finish and straightforward design make this refrigerator a perfect fit for any kitchen decor, providing both style and functionality.\nTechnical Specifications:\n\nDimensions: 68 x 24 x 28 inches (H x W x D)\nColor: White\nCapacity: 7.0 cubic feet (4.5 cu. ft. refrigerator, 2.5 cu. ft. freezer)\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\nElevate your kitchen's efficiency and style with the Hoover Classic Top-Mount Refrigerator-Freezer, a dependable choice for keeping your food fresh and your kitchen organized.",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/1749f570-c2bc-40ef-97a6-1ce2e6ecdef4__MG_7403.jpg"
        ],
        "brand": "Hoover",
        "price": 263750,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666aa55aa99dde00296b1219",
        "rating": 0,
        "name": "Hoover Classic Top-Mount Refrigerator-Freezer - 7.0 Cu. Ft., White",
        "description": "The Hoover Classic Top-Mount Refrigerator-Freezer is a versatile and spacious appliance designed to meet the demands of modern kitchens. With a clean white finish and practical design, this refrigerator-freezer combo ensures your food stays fresh and organized.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "666b0981aaaed6424d1ec13d",
        "dateCreated": "2024-06-13T07:52:58.123Z",
        "__v": 0,
        "id": "666aa55aa99dde00296b1219"
    },
    {
        "richDescription": "Key Features:\n\nCompact and Portable: With a 1.6 cubic feet capacity, this mini fridge is ideal for small spaces, offering sufficient storage without taking up too much room.\nLockable Door: The built-in lock provides added security, making it perfect for shared spaces where you want to keep your items safe.\nEfficient Cooling: Equipped with a high-efficiency compressor, the Subcold Ultra ensures consistent and reliable cooling performance.\nVersatile Storage: The fridge includes a removable shelf, door storage for bottles, and a small freezer compartment, allowing you to organize your items efficiently.\nEco-Friendly Refrigerant: Uses Cyclopentane refrigerant, which is environmentally friendly and helps reduce your carbon footprint.\nDurable Design: Made with high-quality materials, this mini fridge is built to last and withstand daily use.\nTechnical Specifications:\n\nDimensions: 20 x 18 x 18 inches (H x W x D)\nColor: Black\nCapacity: 1.6 cubic feet\nEnergy Rating: A+\nRefrigerant: Cyclopentane\nVoltage: 220-240V\nFrequency: 50Hz\nThe Subcold Ultra Mini Fridge with Lock is a perfect blend of convenience, security, and efficiency, making it an excellent choice for anyone in need of compact refrigeration.",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/ce12a4df-95cb-4746-b3fa-48cac317fbd8__MG_7405.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/fabc561a-a7cf-4373-b04b-769fe9592ca4__MG_7407.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/cd4e1a86-5db8-40b9-a3eb-10fb09c6a164__MG_7411.jpg"
        ],
        "brand": "Subcold",
        "price": 126599.99999999999,
        "realPrice": 0,
        "ratings": [
            {
                "_id": "66a2792b1a453a869bfba733",
                "product": "666ab1a954bceb0027430f71",
                "rating": 5,
                "user": "66902ea942693800293f7f72",
                "createdAt": "2024-07-25T16:11:23.305Z",
                "__v": 0,
                "id": "66a2792b1a453a869bfba733"
            },
            {
                "_id": "66a279401a453a869bfba738",
                "product": "666ab1a954bceb0027430f71",
                "rating": 4,
                "user": "66902ea942693800293f7f72",
                "createdAt": "2024-07-25T16:11:44.011Z",
                "__v": 0,
                "id": "66a279401a453a869bfba738"
            }
        ],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666ab1a954bceb0027430f71",
        "rating": 4.5,
        "name": "Subcold Ultra Mini Fridge with Lock - 1.6 Cu. Ft., Black",
        "description": "The Subcold Ultra Mini Fridge with Lock is a compact, reliable, and secure cooling solution perfect for small spaces like bedrooms, offices, dorm rooms, or personal bars. With its sleek black finish and lockable door, this mini fridge ensures your beverages and snacks stay cool and secure.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "664bb1ca8db4bc603af579b4",
        "dateCreated": "2024-06-13T08:45:29.247Z",
        "__v": 2,
        "id": "666ab1a954bceb0027430f71"
    },
    {
        "richDescription": "Key Features:\n\nSpacious Capacity: Offering 3.5 cubic feet of storage space, this beverage cooler can accommodate a large variety of drinks, ensuring you always have a refreshing beverage on hand.\nGlass Door Design: The transparent glass door provides clear visibility of the cooler’s contents, allowing you to easily find and display your favorite beverages.\nLockable Door: The built-in lock ensures the safety and security of your beverages, making it ideal for use in shared spaces or commercial environments.\nEfficient Cooling: Equipped with a high-performance compressor, this cooler maintains a consistent and optimal temperature to keep your drinks perfectly chilled.\nAdjustable Shelves: The cooler features adjustable wire shelves, allowing you to customize the storage layout to fit bottles and cans of various sizes.\nDurable Construction: Made with high-quality materials, the Tefcold Beverage Cooler is built to last, providing reliable performance for years to come.\nTechnical Specifications:\n\nDimensions: 33 x 19 x 21 inches (H x W x D)\nColor: Black\nCapacity: 3.5 cubic feet\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\nEnhance your beverage storage with the Tefcold Glass Door Beverage Cooler, a perfect blend of style, functionality, and efficiency for any setting.",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/6c40ca9c-683c-4352-97ee-cbb1a8511e9e__MG_7412.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/e553c4ac-b297-442e-b83f-049d55ebfa79__MG_7413.jpg"
        ],
        "brand": "Tefcold",
        "price": 131875,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666ab33054bceb0027430f7a",
        "rating": 0,
        "name": "Tefcold Glass Door Beverage Cooler - 3.5 Cu. Ft., Black",
        "description": "The Tefcold Glass Door Beverage Cooler is an excellent addition to any bar, restaurant, or home entertainment area, offering stylish and efficient storage for your beverages. With its sleek black finish and transparent glass door, this cooler not only provides optimal cooling performance but also allows for easy visibility of your drinks.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "664bb1ca8db4bc603af579b4",
        "dateCreated": "2024-06-13T08:52:00.736Z",
        "__v": 0,
        "id": "666ab33054bceb0027430f7a"
    },
    {
        "richDescription": "Key Features:\n\nEcoBubble Technology: Creates bubbles that activate detergent quickly, allowing it to penetrate fabrics deeply and remove dirt effectively even in cold water, ensuring gentle care for your clothes.\nDigital Inverter Motor: Delivers powerful, efficient performance with less noise and vibration, backed by a 10-year warranty for long-lasting durability.\nMultiple Wash Programs: Features various wash programs to cater to different fabric types and washing needs, including cotton, synthetics, baby care, eco drum clean, and more.\nEnergy Efficient: Designed to be energy-efficient, helping you save on utility bills while reducing your carbon footprint.\nUser-Friendly Interface: The intuitive control panel with a digital display makes it easy to select and monitor your wash cycles.\nDurable Build: Constructed with high-quality materials, the machine is built to withstand regular use and provide reliable performance.\nTechnical Specifications:\n\nDimensions: 33 x 23 x 25 inches (H x W x D)\nColor: Silver\nCapacity: 8 kg\nSpin Speed: 1400 RPM\nEnergy Rating: A+++\nVoltage: 220-240V\nFrequency: 50Hz\nExperience superior washing performance with the Samsung EcoBubble Front Load Washing Machine, a perfect combination of efficiency, innovation, and durability for your laundry needs.",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/2e367d4a-1611-49ce-8248-da7b5b7486eb__MG_7417.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/1448e2d3-76da-486d-9b80-7a15b08e44aa__MG_7419.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/926497fb-83fa-44f8-a685-4519a5d380de__MG_7421.jpg"
        ],
        "brand": "Samsung",
        "price": 316500,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666ab6051bb05c002842e346",
        "rating": 0,
        "name": "Samsung EcoBubble Front Load Washing Machine - 7 Kg, Silver",
        "description": "The Samsung EcoBubble Front Load Washing Machine combines advanced technology with efficient performance, providing a superior cleaning experience while being gentle on your clothes. With a sleek silver finish and a capacity of 7 kg, this washing machine is perfect for medium-sized households.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "664bb1ca8db4bc603af579b4",
        "dateCreated": "2024-06-13T09:04:05.218Z",
        "__v": 0,
        "id": "666ab6051bb05c002842e346"
    },
    {
        "richDescription": "Key Features:\n\nElegant Design: The minimalist Scandinavian design adds a touch of modern sophistication to any dining room or kitchen.\nNatural Wood Finish: The chairs are crafted from high-quality wood with a beautiful natural finish, offering a warm and inviting appearance.\nStackable for Convenience: Designed to stack easily, these chairs save space and are ideal for small living areas or for extra seating when needed.\nComfortable Seating: The curved backrest and ergonomic seat ensure comfort during meals or extended gatherings.\nDurable Construction: Made with sturdy materials, these chairs are built to last, providing reliable use for years to come.\nVersatile Use: Suitable for dining rooms, kitchens, cafes, or as additional seating for guests.\nTechnical Specifications:\n\nDimensions (Each Chair): 31 x 18 x 19 inches (H x W x D)\nMaterial: High-quality wood\nColor: Natural wood finish\nWeight Capacity: 250 lbs (each chair)\nSet Includes: 4 stackable dining chairs\nUpgrade your dining space with the Scandinavian Modern Stackable Dining Chairs, combining practicality and style to create an inviting atmosphere for you and your guests.",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/0863271f-73a3-4acb-adc7-0c1d223daf18__MG_7435.jpg"
        ],
        "brand": "Chair",
        "price": 21100,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666ab8fedb44140028facc29",
        "rating": 0,
        "name": "Scandinavian Modern Stackable Dining Chairs - Set of 4, Natural Wood Finish",
        "description": "Enhance your dining area with the elegant simplicity of the Scandinavian Modern Stackable Dining Chairs. This set of four chairs features a sleek design and natural wood finish, providing both functionality and style. Perfect for small spaces, these chairs offer the convenience of stackability, making them easy to store when not in use.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "664bb1ca8db4bc603af579b4",
        "dateCreated": "2024-06-13T09:16:46.184Z",
        "__v": 0,
        "id": "666ab8fedb44140028facc29"
    },
    {
        "richDescription": "Key Features:\n\nContemporary Design: The bentwood design with a smooth honey finish and chrome legs provides a modern and stylish look that complements any decor.\nStackable for Convenience: These chairs are designed to stack easily, making them ideal for small spaces and easy storage when not in use.\nDurable Construction: Made with high-quality bentwood and sturdy chrome legs, these chairs are built to last and can withstand regular use.\nComfortable Seating: The ergonomic design offers comfortable seating, making these chairs suitable for extended use in dining rooms, offices, or conference rooms.\nVersatile Use: Perfect for dining areas, offices, cafes, and more, these chairs provide a versatile seating solution for various settings.\nTechnical Specifications:\n\nDimensions (Each Chair): 31 x 18 x 20 inches (H x W x D)\nMaterial: Bentwood seat and backrest, chrome legs\nColor: Honey wood finish\nWeight Capacity: 250 lbs (each chair)\nSet Includes: 3 stackable bentwood chairs\nUpgrade your seating arrangement with the Modern Stackable Bentwood Chairs with Chrome Legs, offering a stylish and practical solution for any space.",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/547fa433-d4b9-4632-bc62-3746566d6744__MG_7437.jpg"
        ],
        "brand": "Chair",
        "price": 21100,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666ab9de1bb05c002842e357",
        "rating": 0,
        "name": "Modern Stackable Bentwood Chairs with Chrome Legs - Set of 3, Honey Finish",
        "description": "Add a contemporary touch to your home or office with the Modern Stackable Bentwood Chairs. This set of three chairs features a sleek honey wood finish and sturdy chrome legs, offering both style and practicality. Designed for versatility, these chairs are perfect for dining rooms, conference rooms, or additional seating, and can be easily stacked when not in use to save space.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "664bb1ca8db4bc603af579b4",
        "dateCreated": "2024-06-13T09:20:30.774Z",
        "__v": 0,
        "id": "666ab9de1bb05c002842e357"
    },
    {
        "richDescription": "Key Features:\n\nSleek Modern Design: The black seat and backrest paired with chrome legs create a professional and modern look, perfect for any office or meeting space.\nStackable for Easy Storage: Designed to stack easily, these chairs save space and can be conveniently stored when not in use, making them ideal for multi-purpose rooms.\nComfortable Seating: The cushioned seat and ergonomic backrest provide comfort for extended periods, ensuring a pleasant sitting experience during long meetings or work sessions.\nDurable Construction: Made with high-quality materials, including a sturdy chrome frame, these chairs are built to withstand regular use in busy environments.\nVersatile Use: Suitable for offices, conference rooms, training rooms, and reception areas, these chairs offer a versatile seating solution for various professional settings.\nTechnical Specifications:\n\nDimensions (Each Chair): 33 x 22 x 20 inches (H x W x D)\nMaterial: Cushioned fabric seat and backrest, chrome legs\nColor: Black\nWeight Capacity: 250 lbs (each chair)\nSet Includes: 4 stackable office chairs\nEnhance your office environment with the Modern Stackable Office Chairs with Chrome Legs, providing stylish, comfortable, and space-saving seating for your professional needs.",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/035b03c1-3f54-4f6d-9b03-ae1b01f4a161__MG_7438.jpg"
        ],
        "brand": "Chair",
        "price": 21100,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666abaf31bb05c002842e360",
        "rating": 0,
        "name": "Modern Stackable Office Chairs with Chrome Legs - Set of 4, Black",
        "description": "Upgrade your office or meeting room with the sleek and practical Modern Stackable Office Chairs. This set of four chairs features a contemporary design with a black seat and backrest, complemented by durable chrome legs. These chairs are designed for both comfort and convenience, making them ideal for any professional setting.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "664bb1ca8db4bc603af579b4",
        "dateCreated": "2024-06-13T09:25:07.205Z",
        "__v": 0,
        "id": "666abaf31bb05c002842e360"
    },
    {
        "richDescription": "Key Features:\n\nErgonomic Design: The contoured mesh back provides optimal lumbar support, promoting good posture and reducing back strain during long working hours.\nBreathable Mesh Material: The breathable mesh fabric keeps you cool and comfortable, even during extended use, by allowing air to circulate freely.\nAdjustable Height: The chair features an adjustable height mechanism, ensuring you can customize it to fit your desk and your comfort needs.\nDurable Construction: Made with high-quality materials, including a sturdy frame and smooth-rolling casters, this chair is built to last and withstand daily use.\nSleek and Modern Look: The brown mesh and black frame offer a contemporary aesthetic that complements any office decor.\n360-Degree Swivel: The 360-degree swivel feature allows for easy maneuverability, making it convenient to move around your workspace.\nTechnical Specifications:\n\nDimensions: 40 x 24 x 24 inches (H x W x D)\nMaterial: Mesh fabric seat and backrest, metal frame\nColor: Brown\nWeight Capacity: 250 lbs\nAdjustable Height Range: 18 to 22 inches\nBase Type: 5-point caster base\nEnhance your workspace with the Ergonomic Mesh Office Chair, designed to keep you comfortable and supported throughout your workday.",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/9d73d39f-166a-4c20-9f5c-2c8cc646cb5f__MG_7441.jpg"
        ],
        "brand": "Chair",
        "price": 21100,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666abbbedb44140028facc3a",
        "rating": 0,
        "name": "Ergonomic Mesh Office Chair - Brown",
        "description": "Stay comfortable and productive with the Ergonomic Mesh Office Chair, designed to provide excellent support and breathability. This stylish brown office chair combines a sleek design with practical features, making it a perfect addition to any modern workspace.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "664bb1ca8db4bc603af579b4",
        "dateCreated": "2024-06-13T09:28:30.438Z",
        "__v": 0,
        "id": "666abbbedb44140028facc3a"
    },
    {
        "richDescription": "Key Features:\n\nErgonomic Design: The contoured mesh back provides optimal lumbar support, promoting good posture and reducing back strain during long working hours.\nBreathable Mesh Material: The breathable mesh fabric keeps you cool and comfortable, even during extended use, by allowing air to circulate freely.\nAdjustable Height: The chair features an adjustable height mechanism, ensuring you can customize it to fit your desk and your comfort needs.\nDurable Construction: Made with high-quality materials, including a sturdy frame and smooth-rolling casters, this chair is built to last and withstand daily use.\nSleek and Modern Look: The brown mesh and black frame offer a contemporary aesthetic that complements any office decor.\n360-Degree Swivel: The 360-degree swivel feature allows for easy maneuverability, making it convenient to move around your workspace.\nTechnical Specifications:\n\nDimensions: 40 x 24 x 24 inches (H x W x D)\nMaterial: Mesh fabric seat and backrest, metal frame\nColor: Brown\nWeight Capacity: 250 lbs\nAdjustable Height Range: 18 to 22 inches\nBase Type: 5-point caster base\nEnhance your workspace with the Ergonomic Mesh Office Chair, designed to keep you comfortable and supported throughout your workday.",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/7c9b23e0-b062-4674-81b7-2a9481e7e8c1__MG_7442.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/c0059616-2a01-4a39-92f9-9b805cad8050__MG_7444.jpg"
        ],
        "brand": "Chair",
        "price": 21100,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666abd059f289200298b5e78",
        "rating": 0,
        "name": "Ergonomic Mesh Office Chair - Bright Orange",
        "description": "Stay comfortable and productive with the Ergonomic Mesh Office Chair, designed to provide excellent support and breathability. This stylish brown office chair combines a sleek design with practical features, making it a perfect addition to any modern workspace.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "664bb1ca8db4bc603af579b4",
        "dateCreated": "2024-06-13T09:33:57.256Z",
        "__v": 0,
        "id": "666abd059f289200298b5e78"
    },
    {
        "richDescription": "Key Features:\n\nStylish Design: The combination of a red cushioned seat and a white perforated backrest creates a modern and attractive look, suitable for various decor styles.\nStackable for Easy Storage: These chairs are designed to stack easily, allowing for efficient storage and space-saving when not in use.\nComfortable Seating: The plush cushioned seat provides comfort for extended use, while the ergonomic backrest offers support during meetings, conferences, or events.\nDurable Construction: Made with high-quality materials, including a robust chrome frame, these chairs are built to withstand frequent use in busy environments.\nVersatile Use: Ideal for conference rooms, training sessions, waiting areas, and social gatherings, these chairs offer a versatile seating solution for any occasion.\nTechnical Specifications:\n\nDimensions (Each Chair): 33 x 20 x 20 inches (H x W x D)\nMaterial: Cushioned fabric seat, perforated plastic backrest, chrome legs\nColor: Red seat, white backrest\nWeight Capacity: 250 lbs (each chair)\nSet Includes: 2 stackable conference chairs\nEnhance your space with the Modern Stackable Conference Chairs, offering a stylish, comfortable, and practical seating option for any professional or social environment.",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/6bf7bda5-dd14-44cd-8294-5face5d71fe4__MG_7446.jpg"
        ],
        "brand": "Chair",
        "price": 31649.999999999996,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666abe8edb44140028facc46",
        "rating": 0,
        "name": "Modern Stackable Conference Chairs with Cushioned Seat - Set of 2, Red and White",
        "description": "Add a vibrant touch to your meeting rooms or event spaces with the Modern Stackable Conference Chairs. This set of two chairs features a bold red cushioned seat paired with a sleek white perforated backrest and sturdy chrome legs. Designed for both comfort and convenience, these chairs are perfect for any professional or social setting.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "664bb1ca8db4bc603af579b4",
        "dateCreated": "2024-06-13T09:40:30.703Z",
        "__v": 0,
        "id": "666abe8edb44140028facc46"
    },
    {
        "richDescription": "Key Features:\n\nErgonomic Design: The mesh backrest provides excellent lumbar support, promoting good posture and reducing back strain.\nBreathable Mesh Material: The mesh fabric allows for airflow, keeping you cool and comfortable throughout the day.\nAdjustable Armrests: The armrests can be adjusted to suit your preference, providing additional support for your arms and shoulders.\nCushioned Seat: The generously padded blue seat offers comfort and support, making these chairs ideal for extended use.\nDurable Construction: Built with high-quality materials, these chairs are designed to withstand daily use in a busy office environment.\n360-Degree Swivel and Casters: The 360-degree swivel feature and smooth-rolling casters provide easy maneuverability and flexibility in your workspace.\nTechnical Specifications:\n\nDimensions (Each Chair): 40 x 24 x 24 inches (H x W x D)\nMaterial: Mesh fabric backrest, cushioned fabric seat, plastic and metal frame\nColor: Black and Blue\nWeight Capacity: 250 lbs (each chair)\nAdjustable Height Range: 18 to 22 inches\nBase Type: 5-point caster base\nEnhance your office environment with the Ergonomic Mesh Office Chairs with Adjustable Armrests, providing the perfect combination of comfort and functionality for any professional setting.",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/94c5a513-3e92-4667-bb36-4928c14a0016__MG_7448.jpg"
        ],
        "brand": "Chair",
        "price": 31649.999999999996,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666abf62db44140028facc4d",
        "rating": 0,
        "name": "Ergonomic Mesh Office Chairs with Adjustable Armrests - Set of 2, Black and Blue",
        "description": "Upgrade your workspace with the Ergonomic Mesh Office Chairs. This set of two chairs offers a blend of comfort, functionality, and modern design. Featuring a breathable mesh back, cushioned blue seat, and adjustable armrests, these chairs are perfect for enhancing productivity and comfort during long working hours.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "664bb1ca8db4bc603af579b4",
        "dateCreated": "2024-06-13T09:44:02.512Z",
        "__v": 0,
        "id": "666abf62db44140028facc4d"
    },
    {
        "richDescription": "Key Features:\n\nStylish Design: The mix of a gray upholstered chair with a wooden base and a green fabric chair with metal legs offers a contemporary aesthetic that complements any decor.\nComfortable Seating: Both chairs are designed for comfort, featuring plush cushioning and ergonomic shapes that provide excellent support for relaxed seating.\nDurable Construction: Made with high-quality materials, these chairs and the coffee table are built to last, ensuring long-term use in your home or office.\nVersatile Use: Ideal for living rooms, lounges, offices, or reception areas, this set provides a versatile seating solution that enhances any space.\nSleek Glass Table: The round glass coffee table with metal legs adds a touch of elegance and functionality, perfect for holding drinks, books, or decor items.\nTechnical Specifications:\n\nChair Dimensions (Gray Chair): 30 x 25 x 25 inches (H x W x D)\nChair Dimensions (Green Chair): 30 x 25 x 25 inches (H x W x D)\nTable Dimensions: 18 x 30 inches (H x Diameter)\nMaterial: Upholstered fabric, wood, metal, glass\nColor: Gray chair, green chair, glass table\nWeight Capacity (Each Chair): 250 lbs\nEnhance your seating area with the Contemporary Lounge Chair Set, combining style, comfort, and functionality for an inviting and modern look.\n\n\n\n\n\n\n",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/41633747-2034-46c4-bce2-72a1e6f2018f__MG_7451.jpg"
        ],
        "brand": "Chair",
        "price": 31649.999999999996,
        "realPrice": 0,
        "ratings": [],
        "numReviews": 10,
        "isFeatured": false,
        "status": "available",
        "_id": "666ac047db44140028facc54",
        "rating": 0,
        "name": "Contemporary Lounge Chair Set with Glass Coffee Table - 2 Chairs, Gray and Green",
        "description": "Elevate your living space or office lounge with the Contemporary Lounge Chair Set, featuring two stylish chairs and a sleek glass coffee table. This set includes a comfortable gray chair with a wooden base and a modern green chair with metal legs, paired perfectly with a round glass coffee table, creating a cozy and sophisticated seating area.",
        "category": {
            "_id": "5f15d5b2cb4a6642bddc0fe7",
            "name": "Home",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "home",
            "id": "5f15d5b2cb4a6642bddc0fe7"
        },
        "countInStock": 1,
        "owner": "664bb1ca8db4bc603af579b4",
        "dateCreated": "2024-06-13T09:47:51.161Z",
        "__v": 0,
        "id": "666ac047db44140028facc54"
    },
    {
        "richDescription": "This is a test product",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/29b74085-d8bb-499d-b94f-ed7374ea3347_photo.jpg"
        ],
        "brand": "Ratokunbo",
        "price": 2110,
        "realPrice": 2000,
        "ratings": [],
        "numReviews": 0,
        "isFeatured": false,
        "status": "available",
        "_id": "666c1ad8fd8ed30029a69e2a",
        "rating": 0,
        "name": "Test product",
        "description": "This is a test product",
        "category": {
            "_id": "5f15d467f3a046427a1c26e1",
            "name": "Mobile",
            "__v": 0,
            "color": "#F0E4E1",
            "icon": "settings_cell",
            "id": "5f15d467f3a046427a1c26e1"
        },
        "countInStock": 1,
        "owner": "666b0981aaaed6424d1ec13d",
        "dateCreated": "2024-06-14T10:26:32.090Z",
        "__v": 0,
        "id": "666c1ad8fd8ed30029a69e2a"
    },
    {
        "richDescription": "Just testing to see how it flows through ",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/0c7d03be-732a-45cb-bfa4-4629c98afa8d_image0.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/d97f6da7-8389-4834-83bc-ca7311e4d16c_image1.jpg"
        ],
        "brand": "Ratokunbo",
        "price": 105500,
        "realPrice": 100000,
        "ratings": [],
        "numReviews": 0,
        "isFeatured": false,
        "status": "available",
        "_id": "6671313d19600a0028bfb168",
        "rating": 0,
        "name": "Test listing ",
        "description": "Just testing to see how it flows through ",
        "category": {
            "_id": "5f9d7e938680aa1d979d7e19",
            "name": "Games",
            "__v": 0,
            "color": null,
            "icon": "games",
            "id": "5f9d7e938680aa1d979d7e19"
        },
        "countInStock": 10,
        "owner": "666b0981aaaed6424d1ec13d",
        "dateCreated": "2024-06-18T07:03:25.787Z",
        "__v": 0,
        "id": "6671313d19600a0028bfb168"
    },
    {
        "richDescription": "Super fast lenovo computer",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/13156059-a2d0-4909-b7e5-bd0b5d51977e_image0.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/d216501a-c090-496f-ad78-fc12f1e067f0_image1.jpg"
        ],
        "brand": "Ratokunbo",
        "price": 105.5,
        "realPrice": 100,
        "ratings": [],
        "numReviews": 0,
        "isFeatured": false,
        "status": "available",
        "_id": "667412f042693800293f7143",
        "rating": 0,
        "name": "Lenovo thinkpad laptop",
        "description": "Super fast lenovo computer",
        "category": {
            "_id": "5f88b12f071fce38bbaad5a5",
            "name": "Cars",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "commute",
            "id": "5f88b12f071fce38bbaad5a5"
        },
        "countInStock": 2,
        "owner": "666b0981aaaed6424d1ec13d",
        "dateCreated": "2024-06-20T11:30:56.120Z",
        "__v": 0,
        "id": "667412f042693800293f7143"
    },
    {
        "richDescription": "Testing supplier price ",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/ce4cbd20-3b5f-4451-8b16-66fd5f62cda8_image0.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/335a3ca7-d091-4c9c-af6c-cae0ec828e47_image1.jpg"
        ],
        "brand": "Ratokunbo",
        "price": 10550,
        "realPrice": 10000,
        "ratings": [],
        "numReviews": 0,
        "isFeatured": false,
        "status": "available",
        "_id": "66743adf42693800293f7150",
        "rating": 0,
        "name": "Test Pricing ",
        "description": "Testing supplier price ",
        "category": {
            "_id": "5f15d54cf3a046427a1c26e3",
            "name": "Computers",
            "__v": 0,
            "color": "#E1F0E7",
            "icon": "computer",
            "id": "5f15d54cf3a046427a1c26e3"
        },
        "countInStock": 20,
        "owner": "666b0981aaaed6424d1ec13d",
        "dateCreated": "2024-06-20T14:21:19.312Z",
        "__v": 0,
        "id": "66743adf42693800293f7150"
    },
    {
        "richDescription": "Helps",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/5f0025e6-0616-4854-b37c-211f331d3298_image0.jpg"
        ],
        "brand": "Ratokunbo",
        "price": 1302.925,
        "realPrice": 1235,
        "ratings": [],
        "numReviews": 0,
        "isFeatured": false,
        "status": "available",
        "_id": "6676de3c444d2e00291ddc16",
        "rating": 0,
        "name": "Joshs",
        "description": "Helps",
        "category": {
            "_id": "5f15d54cf3a046427a1c26e3",
            "name": "Computers",
            "__v": 0,
            "color": "#E1F0E7",
            "icon": "computer",
            "id": "5f15d54cf3a046427a1c26e3"
        },
        "countInStock": 12,
        "owner": "666b0981aaaed6424d1ec13d",
        "dateCreated": "2024-06-22T14:22:52.894Z",
        "__v": 0,
        "id": "6676de3c444d2e00291ddc16"
    },
    {
        "richDescription": "Bans",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/f3eb5d4e-2b9b-41f0-b645-9fb689d398b5_image0.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/d02379ec-5251-472f-81b2-f61fce5c7e77_image1.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/a2448a7f-7274-45c6-b50e-ad80166792a2_image2.jpg"
        ],
        "brand": "Ratokunbo",
        "price": 2237.6549999999997,
        "realPrice": 2121,
        "ratings": [],
        "numReviews": 0,
        "isFeatured": false,
        "status": "available",
        "_id": "6676e53a42693800293f7e34",
        "rating": 0,
        "name": "Yam",
        "description": "Bans",
        "category": {
            "_id": "5f88b12f071fce38bbaad5a5",
            "name": "Cars",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "commute",
            "id": "5f88b12f071fce38bbaad5a5"
        },
        "countInStock": 1,
        "owner": "666b0981aaaed6424d1ec13d",
        "dateCreated": "2024-06-22T14:52:42.411Z",
        "__v": 0,
        "id": "6676e53a42693800293f7e34"
    },
    {
        "richDescription": "Just emailed ",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/63bc8b9b-3d13-4edf-9f5a-1e70bd063b49_image0.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/bf3db4bf-87ba-4847-b5b8-c217acdeca69_image1.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/6c79629e-1802-47e2-baf7-bf076339784f_image2.jpg"
        ],
        "brand": "Ratokunbo",
        "price": 2110,
        "realPrice": 2000,
        "ratings": [],
        "numReviews": 0,
        "isFeatured": false,
        "status": "available",
        "_id": "66770219444d2e00291ddc4a",
        "rating": 0,
        "name": "Loti",
        "description": "Just emailed ",
        "category": {
            "_id": "5f15d467f3a046427a1c26e1",
            "name": "Mobile",
            "__v": 0,
            "color": "#F0E4E1",
            "icon": "settings_cell",
            "id": "5f15d467f3a046427a1c26e1"
        },
        "countInStock": 12,
        "owner": "666b0981aaaed6424d1ec13d",
        "dateCreated": "2024-06-22T16:55:53.153Z",
        "__v": 0,
        "id": "66770219444d2e00291ddc4a"
    },
    {
        "richDescription": "Testing uploads ",
        "image": "",
        "images": [
            "https://ratokunbo-products-images.s3.amazonaws.com/cfe340f7-4cb3-4173-be47-f95d411bb812_image0.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/4625236e-49a3-47f6-a343-39a5abc909dd_image1.jpg",
            "https://ratokunbo-products-images.s3.amazonaws.com/ec00d733-7511-43a1-8a87-831d8b418d9e_image2.jpg"
        ],
        "brand": "Ratokunbo",
        "price": 527.5,
        "realPrice": 500,
        "ratings": [],
        "numReviews": 0,
        "isFeatured": false,
        "status": "available",
        "_id": "66770479444d2e00291ddc58",
        "rating": 0,
        "name": "New product",
        "description": "Testing uploads ",
        "category": {
            "_id": "5f88b12f071fce38bbaad5a5",
            "name": "Cars",
            "__v": 0,
            "color": "#E2E1F0",
            "icon": "commute",
            "id": "5f88b12f071fce38bbaad5a5"
        },
        "countInStock": 10,
        "owner": "666b0981aaaed6424d1ec13d",
        "dateCreated": "2024-06-22T17:06:01.236Z",
        "__v": 0,
        "id": "66770479444d2e00291ddc58"
    }
];


export const products = [
    {
      "richDescription": "The Hoover Compact Single-Door Upright Freezer is the perfect addition to any home, office, or dorm room, offering reliable and efficient storage for your frozen goods. This sleek, black freezer combines functionality with a modern design, ensuring it complements any space.\n\nKey Features:\n\nCompact Design: The freezer's compact size makes it ideal for small spaces, such as apartments, offices, or dorm rooms.\nSpacious Interior: Despite its compact exterior, the freezer boasts a generous interior space with four transparent drawers, allowing for easy organization and access to your frozen items.\nEnergy Efficient: Equipped with a high-efficiency compressor, this freezer ensures your food stays frozen while keeping energy consumption low.\nDurable Build: The robust build quality and scratch-resistant exterior ensure longevity, even in busy households or workplaces.\nEasy Maintenance: The removable drawers and simple interior design make cleaning a breeze, ensuring your freezer stays hygienic and odor-free.\nEnvironmentally Friendly: Uses R-600a refrigerant, which is eco-friendly and helps reduce your carbon footprint.\nTechnical Specifications:\n\nDimensions: 24.5 x 22 x 33 inches (H x W x D)\nColor: Black\nCapacity: 3.5 cubic feet\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\n",
      "image": "",
      "images": [
          "https://ratokunbo-products-images.s3.amazonaws.com/a586632c-76a4-400a-93e0-67645891c000__MG_7384.jpg",
          "https://ratokunbo-products-images.s3.amazonaws.com/cb4e8442-9e06-401c-b6f3-4ec8f8d381fc__MG_7388.jpg",
          "https://ratokunbo-products-images.s3.amazonaws.com/a2f4b308-f891-46a6-84b4-90ddcefa9edf__MG_7392.jpg"
      ],
      "brand": "Hoover",
      "price": 158250,
      "realPrice": 0,
      "numReviews": 10,
      "isFeatured": false,
      "status": "available",
      "_id": "666aa30ca99dde00296b11fb",
      "rating": 2,
      "name": "Hoover Compact Single-Door Upright Freezer",
      "description": "Backed by Hoover’s reliable brand reputation, this compact upright freezer is an excellent choice for those in need of efficient and dependable freezing capabilities in a stylish, space-saving design.\n\n\n\n\n\n",
      "category": {
          "_id": "5f15d5b2cb4a6642bddc0fe7",
          "name": "Home",
          "__v": 0,
          "color": "#E2E1F0",
          "icon": "home",
          "id": "5f15d5b2cb4a6642bddc0fe7"
      },
      "countInStock": 1,
      "owner": "666b0981aaaed6424d1ec13d",
      "dateCreated": "2024-06-13T07:43:08.524Z",
      "__v": 7,
      "id": "666aa30ca99dde00296b11fb"
  },
  {
      "richDescription": "Key Features:\n\nStylish Retro Design: The eye-catching red finish and chrome handles bring a nostalgic charm to your kitchen decor, making this refrigerator a stylish addition to any home.\nSpacious Storage: With a total capacity of 6.5 cubic feet, this refrigerator-freezer combo offers plenty of space to store your groceries. The top-mount freezer provides easy access to frozen foods, while the main refrigerator compartment includes adjustable shelves, door bins, and a spacious crisper drawer.\nEnergy Efficient: Designed with energy efficiency in mind, this refrigerator helps you save on electricity bills while keeping your food fresh and your frozen items well-preserved.\nDurable Construction: Built with high-quality materials, the Montpellier Retro Style Refrigerator-Freezer is made to last, ensuring reliable performance for years to come.\nEasy Maintenance: The removable shelves and door bins make cleaning and organizing a breeze, helping you keep your fridge in pristine condition with minimal effort.\nTechnical Specifications:\n\nDimensions: 65 x 24 x 27 inches (H x W x D)\nColor: Red\nCapacity: 6.5 cubic feet (4.5 cu. ft. refrigerator, 2.0 cu. ft. freezer)\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz",
      "image": "",
      "images": [
          "https://ratokunbo-products-images.s3.amazonaws.com/9e23d2d5-1236-46fa-bd70-cccb82314200__MG_7396.jpg",
          "https://ratokunbo-products-images.s3.amazonaws.com/a188a7ff-1b56-4169-9666-bf3b8b5d1143__MG_7399.jpg",
          "https://ratokunbo-products-images.s3.amazonaws.com/c4c8f545-3faf-408c-abd9-72624ebc8430__MG_7401.jpg"
      ],
      "brand": "Montpellier",
      "price": 211000,
      "realPrice": 0,
      "ratings": [],
      "numReviews": 10,
      "isFeatured": false,
      "status": "available",
      "_id": "666aa45ca99dde00296b120f",
      "rating": 0,
      "name": "Montpellier Retro Style Top-Mount Refrigerator-Freezer - 6.5 Cu. Ft., Red",
      "description": "Add a touch of retro flair to your kitchen with the Montpellier Retro Style Top-Mount Refrigerator-Freezer. Combining vintage aesthetics with modern functionality, this vibrant red appliance not only makes a statement but also provides ample storage for your fresh and frozen foods.",
      "category": {
          "_id": "5f15d5b2cb4a6642bddc0fe7",
          "name": "Home",
          "__v": 0,
          "color": "#E2E1F0",
          "icon": "home",
          "id": "5f15d5b2cb4a6642bddc0fe7"
      },
      "countInStock": 1,
      "owner": "666b0981aaaed6424d1ec13d",
      "dateCreated": "2024-06-13T07:48:44.322Z",
      "__v": 0,
      "id": "666aa45ca99dde00296b120f"
  },
  {
      "richDescription": "Key Features:\n\nGenerous Capacity: Offering 7.0 cubic feet of total storage space, this refrigerator-freezer provides ample room for all your fresh and frozen groceries.\nTop-Mount Freezer: The convenient top-mount freezer allows for easy access to frozen foods and includes adjustable shelves to accommodate various items.\nEnergy Efficient: Designed to minimize energy consumption, this refrigerator helps you save on electricity bills while maintaining optimal cooling performance.\nDurable Construction: Built with high-quality materials, the Hoover Classic refrigerator is designed to withstand daily use, ensuring long-lasting reliability.\nEasy to Clean: The smooth surfaces and removable shelves and bins make cleaning this refrigerator a hassle-free task, helping you maintain a hygienic kitchen environment.\nSimple and Practical Design: The classic white finish and straightforward design make this refrigerator a perfect fit for any kitchen decor, providing both style and functionality.\nTechnical Specifications:\n\nDimensions: 68 x 24 x 28 inches (H x W x D)\nColor: White\nCapacity: 7.0 cubic feet (4.5 cu. ft. refrigerator, 2.5 cu. ft. freezer)\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\nElevate your kitchen's efficiency and style with the Hoover Classic Top-Mount Refrigerator-Freezer, a dependable choice for keeping your food fresh and your kitchen organized.",
      "image": "",
      "images": [
          "https://ratokunbo-products-images.s3.amazonaws.com/1749f570-c2bc-40ef-97a6-1ce2e6ecdef4__MG_7403.jpg"
      ],
      "brand": "Hoover",
      "price": 263750,
      "realPrice": 0,
      "ratings": [],
      "numReviews": 10,
      "isFeatured": false,
      "status": "available",
      "_id": "666aa55aa99dde00296b1219",
      "rating": 0,
      "name": "Hoover Classic Top-Mount Refrigerator-Freezer - 7.0 Cu. Ft., White",
      "description": "The Hoover Classic Top-Mount Refrigerator-Freezer is a versatile and spacious appliance designed to meet the demands of modern kitchens. With a clean white finish and practical design, this refrigerator-freezer combo ensures your food stays fresh and organized.",
      "category": {
          "_id": "5f15d5b2cb4a6642bddc0fe7",
          "name": "Home",
          "__v": 0,
          "color": "#E2E1F0",
          "icon": "home",
          "id": "5f15d5b2cb4a6642bddc0fe7"
      },
      "countInStock": 1,
      "owner": "666b0981aaaed6424d1ec13d",
      "dateCreated": "2024-06-13T07:52:58.123Z",
      "__v": 0,
      "id": "666aa55aa99dde00296b1219"
  },
  {
    "richDescription": "Key Features:\n\nGenerous Capacity: Offering 7.0 cubic feet of total storage space, this refrigerator-freezer provides ample room for all your fresh and frozen groceries.\nTop-Mount Freezer: The convenient top-mount freezer allows for easy access to frozen foods and includes adjustable shelves to accommodate various items.\nEnergy Efficient: Designed to minimize energy consumption, this refrigerator helps you save on electricity bills while maintaining optimal cooling performance.\nDurable Construction: Built with high-quality materials, the Hoover Classic refrigerator is designed to withstand daily use, ensuring long-lasting reliability.\nEasy to Clean: The smooth surfaces and removable shelves and bins make cleaning this refrigerator a hassle-free task, helping you maintain a hygienic kitchen environment.\nSimple and Practical Design: The classic white finish and straightforward design make this refrigerator a perfect fit for any kitchen decor, providing both style and functionality.\nTechnical Specifications:\n\nDimensions: 68 x 24 x 28 inches (H x W x D)\nColor: White\nCapacity: 7.0 cubic feet (4.5 cu. ft. refrigerator, 2.5 cu. ft. freezer)\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\nElevate your kitchen's efficiency and style with the Hoover Classic Top-Mount Refrigerator-Freezer, a dependable choice for keeping your food fresh and your kitchen organized.",
    "image": "",
    "images": [
        "https://ratokunbo-products-images.s3.amazonaws.com/1749f570-c2bc-40ef-97a6-1ce2e6ecdef4__MG_7403.jpg"
    ],
    "brand": "Hoover",
    "price": 263750,
    "realPrice": 0,
    "ratings": [],
    "numReviews": 10,
    "isFeatured": false,
    "status": "available",
    "_id": "666aa55aa99dde00296b1219",
    "rating": 0,
    "name": "Hoover Classic Top-Mount Refrigerator-Freezer - 7.0 Cu. Ft., White",
    "description": "The Hoover Classic Top-Mount Refrigerator-Freezer is a versatile and spacious appliance designed to meet the demands of modern kitchens. With a clean white finish and practical design, this refrigerator-freezer combo ensures your food stays fresh and organized.",
    "category": {
        "_id": "5f15d5b2cb4a6642bddc0fe7",
        "name": "Home",
        "__v": 0,
        "color": "#E2E1F0",
        "icon": "home",
        "id": "5f15d5b2cb4a6642bddc0fe7"
    },
    "countInStock": 1,
    "owner": "666b0981aaaed6424d1ec13d",
    "dateCreated": "2024-06-13T07:52:58.123Z",
    "__v": 0,
    "id": "666aa55aa99dde00296b1219"
},
{
    "richDescription": "Key Features:\n\nGenerous Capacity: Offering 7.0 cubic feet of total storage space, this refrigerator-freezer provides ample room for all your fresh and frozen groceries.\nTop-Mount Freezer: The convenient top-mount freezer allows for easy access to frozen foods and includes adjustable shelves to accommodate various items.\nEnergy Efficient: Designed to minimize energy consumption, this refrigerator helps you save on electricity bills while maintaining optimal cooling performance.\nDurable Construction: Built with high-quality materials, the Hoover Classic refrigerator is designed to withstand daily use, ensuring long-lasting reliability.\nEasy to Clean: The smooth surfaces and removable shelves and bins make cleaning this refrigerator a hassle-free task, helping you maintain a hygienic kitchen environment.\nSimple and Practical Design: The classic white finish and straightforward design make this refrigerator a perfect fit for any kitchen decor, providing both style and functionality.\nTechnical Specifications:\n\nDimensions: 68 x 24 x 28 inches (H x W x D)\nColor: White\nCapacity: 7.0 cubic feet (4.5 cu. ft. refrigerator, 2.5 cu. ft. freezer)\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\nElevate your kitchen's efficiency and style with the Hoover Classic Top-Mount Refrigerator-Freezer, a dependable choice for keeping your food fresh and your kitchen organized.",
    "image": "",
    "images": [
        "https://ratokunbo-products-images.s3.amazonaws.com/1749f570-c2bc-40ef-97a6-1ce2e6ecdef4__MG_7403.jpg"
    ],
    "brand": "Hoover",
    "price": 263750,
    "realPrice": 0,
    "ratings": [],
    "numReviews": 10,
    "isFeatured": false,
    "status": "available",
    "_id": "666aa55aa99dde00296b1219",
    "rating": 0,
    "name": "Hoover Classic Top-Mount Refrigerator-Freezer - 7.0 Cu. Ft., White",
    "description": "The Hoover Classic Top-Mount Refrigerator-Freezer is a versatile and spacious appliance designed to meet the demands of modern kitchens. With a clean white finish and practical design, this refrigerator-freezer combo ensures your food stays fresh and organized.",
    "category": {
        "_id": "5f15d5b2cb4a6642bddc0fe7",
        "name": "Home",
        "__v": 0,
        "color": "#E2E1F0",
        "icon": "home",
        "id": "5f15d5b2cb4a6642bddc0fe7"
    },
    "countInStock": 1,
    "owner": "666b0981aaaed6424d1ec13d",
    "dateCreated": "2024-06-13T07:52:58.123Z",
    "__v": 0,
    "id": "666aa55aa99dde00296b1219"
},
{
    "richDescription": "Key Features:\n\nGenerous Capacity: Offering 7.0 cubic feet of total storage space, this refrigerator-freezer provides ample room for all your fresh and frozen groceries.\nTop-Mount Freezer: The convenient top-mount freezer allows for easy access to frozen foods and includes adjustable shelves to accommodate various items.\nEnergy Efficient: Designed to minimize energy consumption, this refrigerator helps you save on electricity bills while maintaining optimal cooling performance.\nDurable Construction: Built with high-quality materials, the Hoover Classic refrigerator is designed to withstand daily use, ensuring long-lasting reliability.\nEasy to Clean: The smooth surfaces and removable shelves and bins make cleaning this refrigerator a hassle-free task, helping you maintain a hygienic kitchen environment.\nSimple and Practical Design: The classic white finish and straightforward design make this refrigerator a perfect fit for any kitchen decor, providing both style and functionality.\nTechnical Specifications:\n\nDimensions: 68 x 24 x 28 inches (H x W x D)\nColor: White\nCapacity: 7.0 cubic feet (4.5 cu. ft. refrigerator, 2.5 cu. ft. freezer)\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\nElevate your kitchen's efficiency and style with the Hoover Classic Top-Mount Refrigerator-Freezer, a dependable choice for keeping your food fresh and your kitchen organized.",
    "image": "",
    "images": [
        "https://ratokunbo-products-images.s3.amazonaws.com/1749f570-c2bc-40ef-97a6-1ce2e6ecdef4__MG_7403.jpg"
    ],
    "brand": "Hoover",
    "price": 263750,
    "realPrice": 0,
    "ratings": [],
    "numReviews": 10,
    "isFeatured": false,
    "status": "available",
    "_id": "666aa55aa99dde00296b1219",
    "rating": 0,
    "name": "Hoover Classic Top-Mount Refrigerator-Freezer - 7.0 Cu. Ft., White",
    "description": "The Hoover Classic Top-Mount Refrigerator-Freezer is a versatile and spacious appliance designed to meet the demands of modern kitchens. With a clean white finish and practical design, this refrigerator-freezer combo ensures your food stays fresh and organized.",
    "category": {
        "_id": "5f15d5b2cb4a6642bddc0fe7",
        "name": "Home",
        "__v": 0,
        "color": "#E2E1F0",
        "icon": "home",
        "id": "5f15d5b2cb4a6642bddc0fe7"
    },
    "countInStock": 1,
    "owner": "666b0981aaaed6424d1ec13d",
    "dateCreated": "2024-06-13T07:52:58.123Z",
    "__v": 0,
    "id": "666aa55aa99dde00296b1219"
},
{
    "richDescription": "Key Features:\n\nGenerous Capacity: Offering 7.0 cubic feet of total storage space, this refrigerator-freezer provides ample room for all your fresh and frozen groceries.\nTop-Mount Freezer: The convenient top-mount freezer allows for easy access to frozen foods and includes adjustable shelves to accommodate various items.\nEnergy Efficient: Designed to minimize energy consumption, this refrigerator helps you save on electricity bills while maintaining optimal cooling performance.\nDurable Construction: Built with high-quality materials, the Hoover Classic refrigerator is designed to withstand daily use, ensuring long-lasting reliability.\nEasy to Clean: The smooth surfaces and removable shelves and bins make cleaning this refrigerator a hassle-free task, helping you maintain a hygienic kitchen environment.\nSimple and Practical Design: The classic white finish and straightforward design make this refrigerator a perfect fit for any kitchen decor, providing both style and functionality.\nTechnical Specifications:\n\nDimensions: 68 x 24 x 28 inches (H x W x D)\nColor: White\nCapacity: 7.0 cubic feet (4.5 cu. ft. refrigerator, 2.5 cu. ft. freezer)\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\nElevate your kitchen's efficiency and style with the Hoover Classic Top-Mount Refrigerator-Freezer, a dependable choice for keeping your food fresh and your kitchen organized.",
    "image": "",
    "images": [
        "https://ratokunbo-products-images.s3.amazonaws.com/1749f570-c2bc-40ef-97a6-1ce2e6ecdef4__MG_7403.jpg"
    ],
    "brand": "Hoover",
    "price": 263750,
    "realPrice": 0,
    "ratings": [],
    "numReviews": 10,
    "isFeatured": false,
    "status": "available",
    "_id": "666aa55aa99dde00296b1219",
    "rating": 0,
    "name": "Hoover Classic Top-Mount Refrigerator-Freezer - 7.0 Cu. Ft., White",
    "description": "The Hoover Classic Top-Mount Refrigerator-Freezer is a versatile and spacious appliance designed to meet the demands of modern kitchens. With a clean white finish and practical design, this refrigerator-freezer combo ensures your food stays fresh and organized.",
    "category": {
        "_id": "5f15d5b2cb4a6642bddc0fe7",
        "name": "Home",
        "__v": 0,
        "color": "#E2E1F0",
        "icon": "home",
        "id": "5f15d5b2cb4a6642bddc0fe7"
    },
    "countInStock": 1,
    "owner": "666b0981aaaed6424d1ec13d",
    "dateCreated": "2024-06-13T07:52:58.123Z",
    "__v": 0,
    "id": "666aa55aa99dde00296b1219"
},
{
    "richDescription": "Key Features:\n\nGenerous Capacity: Offering 7.0 cubic feet of total storage space, this refrigerator-freezer provides ample room for all your fresh and frozen groceries.\nTop-Mount Freezer: The convenient top-mount freezer allows for easy access to frozen foods and includes adjustable shelves to accommodate various items.\nEnergy Efficient: Designed to minimize energy consumption, this refrigerator helps you save on electricity bills while maintaining optimal cooling performance.\nDurable Construction: Built with high-quality materials, the Hoover Classic refrigerator is designed to withstand daily use, ensuring long-lasting reliability.\nEasy to Clean: The smooth surfaces and removable shelves and bins make cleaning this refrigerator a hassle-free task, helping you maintain a hygienic kitchen environment.\nSimple and Practical Design: The classic white finish and straightforward design make this refrigerator a perfect fit for any kitchen decor, providing both style and functionality.\nTechnical Specifications:\n\nDimensions: 68 x 24 x 28 inches (H x W x D)\nColor: White\nCapacity: 7.0 cubic feet (4.5 cu. ft. refrigerator, 2.5 cu. ft. freezer)\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\nElevate your kitchen's efficiency and style with the Hoover Classic Top-Mount Refrigerator-Freezer, a dependable choice for keeping your food fresh and your kitchen organized.",
    "image": "",
    "images": [
        "https://ratokunbo-products-images.s3.amazonaws.com/1749f570-c2bc-40ef-97a6-1ce2e6ecdef4__MG_7403.jpg"
    ],
    "brand": "Hoover",
    "price": 263750,
    "realPrice": 0,
    "ratings": [],
    "numReviews": 10,
    "isFeatured": false,
    "status": "available",
    "_id": "666aa55aa99dde00296b1219",
    "rating": 0,
    "name": "Hoover Classic Top-Mount Refrigerator-Freezer - 7.0 Cu. Ft., White",
    "description": "The Hoover Classic Top-Mount Refrigerator-Freezer is a versatile and spacious appliance designed to meet the demands of modern kitchens. With a clean white finish and practical design, this refrigerator-freezer combo ensures your food stays fresh and organized.",
    "category": {
        "_id": "5f15d5b2cb4a6642bddc0fe7",
        "name": "Home",
        "__v": 0,
        "color": "#E2E1F0",
        "icon": "home",
        "id": "5f15d5b2cb4a6642bddc0fe7"
    },
    "countInStock": 1,
    "owner": "666b0981aaaed6424d1ec13d",
    "dateCreated": "2024-06-13T07:52:58.123Z",
    "__v": 0,
    "id": "666aa55aa99dde00296b1219"
},
  ]
import React, { useState, useRef, useEffect } from 'react';
import { Input } from '../ui/input';
// import { Input } from "@/components/ui/input";
// import { Button } from "@/components/ui/button";


interface OtpInputProps {
  length: number;
  onComplete: (otp: string) => void;
}

const OtpInput: React.FC<OtpInputProps> = ({ length, onComplete }) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);



  const handleChange = (index: number, value: string) => {
    if (isNaN(Number(value))) return;
    
    const newOtp = [...otp];

    newOtp[index] = value.substring(value.length - 1);

    setOtp(newOtp);

    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newOtp.every(val => val !== '')) {
      onComplete(newOtp.join(''));
    }
    
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
        const otp = index + 1
        const newOtp = otp.toString()
        onComplete(newOtp)
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').slice(0, length);
    const newOtp = [...otp];
    
    for (let i = 0; i < pastedData.length; i++) {
      if (isNaN(Number(pastedData[i]))) return;
      newOtp[i] = pastedData[i];
    }
    
    setOtp(newOtp);
    if (newOtp.every(val => val !== '')) {
      onComplete(newOtp.join(''));
    }
  };

  return (
    <div className="flex gap-4">
      {otp.map((digit, index) => (
        <Input
          key={index}
          type="text"
          maxLength={1}
          value={digit}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onPaste={handlePaste}
          ref={(ref) => inputRefs.current[index] = ref}
          className="w-12 h-12 text-center text-2xl bg-[#F7F7F7]"
        />
      ))}
    </div>
  );
};

export default OtpInput;
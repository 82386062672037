import React, { useState } from 'react'
import Logo from "src/assets/logo.svg";
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Eye, EyeOff, Loader2 } from 'lucide-react';
import { useLoginMutation } from 'src/state/user/userSlice';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';


const Login = () => {
    const [userLogin, setLogin] = useState({email:"", password:"" });
    const [showPassword, setShowPassword] = useState(false);
    const [login, { isLoading, error }] = useLoginMutation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLogin((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleLogin = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
          const response = await login(userLogin).unwrap();
          setLoading(false);
          saveToLocalStorage(response);

          toast.success('Login successful!', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        //   console.log('Login successful:', response);
          navigate('/home')
          // Here you might want to save the token to localStorage
          // and/or update your app's state to reflect that the user is logged in
        } catch (err) {
          setLoading(false);
          toast.error('Login failed. Please check your credentials and try again.', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          console.error('Failed to login:', err);
        }
    }

    const saveToLocalStorage = (response: any) => {
        // Save the entire response as a JSON string
        localStorage.setItem('userSession', JSON.stringify(response));
    
        // Optionally, save individual items for easier access
        localStorage.setItem('userEmail', response?.email);
        localStorage.setItem('firstname', response?.firstname);
        // localStorage.setItem('userId', response?.userId);
      };
    

    // sicroutyoiyaog-6522@yopmail.com
    // Blessing24

    const disabled = Object.values(userLogin).some((text) => text === "");


    return (
        <div className='h-screen w-full'>
            <div className='px-16 flex items-center justify-between '>
                <img src={Logo} alt="Logo" className="w-64 h-28" />
                <p className="text-sm">Don't have an account?<span className='text-md font-semibold ml-2 cursor-pointer' onClick={()=> navigate('/sign-up')}>Sign up</span></p>
            </div>
            <div className='w-full flex justify-center lg:mt-32 mt-16'>
                <div className='p-4 w-[35%]'>
                    <p className='text-2xl font-bold'>Log In</p>
                    <Input type="email" name="email" placeholder='Email address' onChange={handleChange} className='my-8 bg-[#F7F7F7] py-6' />
                    <div className='flex mt-4 mb-8 relative'>
                        <Input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder='Password'
                            onChange={handleChange}
                            className='bg-[#F7F7F7] py-6' />
                        {showPassword ? (
                            <EyeOff onClick={togglePasswordVisibility} className="h-8 w-8 absolute right-5 top-2 cursor-pointer text-slate-400" />
                        ) : (
                            <Eye onClick={togglePasswordVisibility} className="h-8 w-8 absolute right-5 top-2 cursor-pointer text-slate-400" />
                        )}
                    </div>
                    <Button
                        onClick={handleLogin}
                        className='w-full bg-[#F39909] mt-8 cursor-pointer'
                        variant="rato"
                        size={"lg"}
                        disabled={disabled}
                    >{loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : 'Continue'}
                    </Button>
                  <p className='text-center cursor-pointer mt-16 font-semibold' onClick={() => navigate('/forget-password')}>Forget Password?</p>
                </div>
            </div>
        </div>
    )
}

export default Login;

import React, { useState, useEffect } from 'react'
import Logo from "src/assets/logo.svg";
import { Button } from '../components/ui/button';
import OtpInput from "../components/otp";
import { Input } from '../components/ui/input';
import { Eye, EyeOff } from 'lucide-react';


const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [step, setStep] = useState(1);
    const [timer, setTimer] = useState(5);
    const [otp, setOtp] = useState<string>('');
    const [resend, setResend] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {setEmail(e.target.value); };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {setPassword(e.target.value); };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {setConfirmPassword(e.target.value); };

    const handleEmail = () => {
        console.log(email)
        setStep(step + 1)
    }
    const handleComplete = (completedOtp: string) => { setOtp(completedOtp); };

    const handleSubmit = () => {
        // Here you would typically send the OTP to your server for verification
        console.log('Submitting OTP:', otp);
        setStep(step + 1)
        //   toast({
        //     title: "OTP Submitted",
        //     description: `Your OTP ${otp} has been submitted for verification.`,
        //   });
    };

    const handleResetPassword = () => {
        console.log('')
    }

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);


    const handleResend = () => {
        setTimer(5);
        setResend(false);
    };
    useEffect(() => {
        const countdown = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            } else {
                setResend(true);
                clearInterval(countdown);
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [timer]);

    // console.log(otp.length, 'verify length');

    const disabledEmailBtn = email === '';

    const disabledPasswordBtn = password === '' || confirmPassword === '';
    const passwordValid = password.length >= 8 && /\d/.test(password) && /[a-z]/.test(password) && /[A-Z]/.test(password);

    // console.log(otp, "hfhhfhf")
    // const confirmPasswordValid = confirmPassword.length >= 8 && /\d/.test(confirmPassword) && /[a-z]/.test(confirmPassword) && /[A-Z]/.test(confirmPassword) && confirmPassword === password;

    return (
        <div className='h-screen w-full'>
            <div className='px-16 flex items-center justify-between '>
                <img src={Logo} alt="Logo" className="w-64 h-28" />
                <p className="text-sm">Don't have an account?<span className='text-md font-semibold ml-2'>Sign up</span></p>
            </div>
            <div className='w-full flex justify-center lg:mt-32 mt-16'>
                {step === 1 && <div className='p-4 w-[35%]'>
                    <p className='text-4xl font-bold'>Forget password</p>
                    <p className='text-[#585866] text-md my-3'>Enter your registered email address to reset password</p>
                    <Input type="email" name="email" placeholder='Email address' onChange={handleEmailChange} className='my-8 bg-[#F7F7F7] py-6' />
                    <Button
                        onClick={handleEmail}
                        className='w-full bg-[#F39909] mt-8 cursor-pointer'
                        variant="rato"
                        size={"lg"}
                        disabled={disabledEmailBtn}
                    >Continue
                    </Button>
                </div>}
                {step === 2 &&
                    <div className="w-[30%] p-4 flex flex-col gap-4">
                        <h2 className="text-4xl font-bold">Verify your phone number</h2>
                        <p className='text-bold text-sm ml-2'>An OTP has been sent to your phone number ending with 0094.</p>
                        <OtpInput length={4} onComplete={handleComplete} /> 
                        <p className='text-[#585866] text-sm ml-4'>Didn’t receive OTP?
                            {timer > 0 ? (
                                <span className="ml-1 text-slate-800 text-bold">{timer} seconds</span>
                            ) : (
                                (<span onClick={handleResend} className="text-[#F39909] text-sm text-bold ml-2">Resend</span>)
                            )}

                        </p>
                        <Button
                            size="lg"
                            onClick={handleSubmit}
                            disabled={otp.length !== 4 }
                            variant="rato"
                            className={`w-full ${otp.length === 4 ? 'bg-[#F39909]' : 'bg-[#e8cea4]'} cursor-pointer text-white mt-8`}
                        >Continue</Button>
                    </div>
                }
                {step === 3 && 
                <div className='p-4 w-[35%]'>
                    <p className='text-2xl font-bold'>Reset Password</p>
                    <div className='flex mt-4 mb-8 relative'>
                        <Input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder='Password'
                            onChange={handlePasswordChange}
                            className='bg-[#F7F7F7] py-6' />
                        {showPassword ? (
                            <EyeOff onClick={togglePasswordVisibility} className="h-8 w-8 absolute right-5 top-2 cursor-pointer text-slate-400" />
                        ) : (
                            <Eye onClick={togglePasswordVisibility} className="h-8 w-8 absolute right-5 top-2 cursor-pointer text-slate-400" />
                        )}
                    </div>
                    <div className='flex mt-4 mb-2 relative'>
                        <Input
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            placeholder='Confirm password'
                            onChange={handleConfirmPasswordChange}
                            className='bg-[#F7F7F7] py-6' />
                        {showConfirmPassword ? (
                            <EyeOff onClick={toggleConfirmPasswordVisibility} className="h-8 w-8 absolute right-5 top-2 cursor-pointer text-slate-400" />
                        ) : (
                            <Eye onClick={toggleConfirmPasswordVisibility} className="h-8 w-8 absolute right-5 top-2 cursor-pointer text-slate-400" />
                        )}
                    </div>
                    {!passwordValid && <p className='text-rose-800 text-xs '>At least 1 letter, a number or symbol, at least 8 characters.</p>}
                    <Button
                        onClick={handleResetPassword}
                        className='w-full bg-[#F39909] mt-10 cursor-pointer'
                        variant="rato"
                        size={"lg"}
                        disabled={disabledPasswordBtn}
                    >Continue
                    </Button>
                </div>}
            </div>
        </div>
    )
}

export default ForgetPassword;

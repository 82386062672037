import React, { useState } from 'react';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Eye, EyeOff } from 'lucide-react';
import { useDeleteUserMutation } from '../state/user/userSlice';

const DeleteAccount = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Use the deleteUser mutation
    const [deleteUser, { isLoading }] = useDeleteUserMutation();

    // Handle email input changes
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

    // Handle password input changes
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

    // Toggle password visibility
    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    // Call API to delete account
    const handleDeleteAccount = async () => {
        if (!email || !password) {
            setErrorMessage('Please enter both your email and password to proceed.');
            return;
        }

        setErrorMessage('');
        setSuccessMessage('');

        try {
            const response = await deleteUser({ email, password }).unwrap();

            // Check if the response is successful
            if (response.success) {
                setSuccessMessage('Your account has been deleted successfully.');
            } else {
                setErrorMessage('Failed to delete account. Please try again.');
            }
        } catch (error: any) {
            // Extract error message from backend
            const backendErrorMessage = error?.data?.message || error?.error || 'An error occurred while trying to delete your account.';
            setErrorMessage(backendErrorMessage);
        }
    };

    return (
        <div className='h-screen w-full flex justify-center items-center'>
            <div className='p-4 w-[35%]'>
                <p className='text-2xl font-bold mb-6'>Delete Account</p>

                {errorMessage && <p className='text-red-500 text-sm mb-4'>{errorMessage}</p>}
                {successMessage && <p className='text-green-500 text-sm mb-4'>{successMessage}</p>}

                <div className='flex flex-col gap-6'>
                    {/* Email Input */}
                    <Input
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}
                        className="bg-[#F7F7F7] py-6"
                    />

                    {/* Password Input */}
                    <div className='relative'>
                        <Input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={handlePasswordChange}
                            className="bg-[#F7F7F7] py-6"
                        />
                        {showPassword ? (
                            <EyeOff onClick={togglePasswordVisibility} className="h-8 w-8 absolute right-5 top-2 cursor-pointer text-slate-400" />
                        ) : (
                            <Eye onClick={togglePasswordVisibility} className="h-8 w-8 absolute right-5 top-2 cursor-pointer text-slate-400" />
                        )}
                    </div>
                </div>

                <Button
                    onClick={handleDeleteAccount}
                    className='w-full bg-[#F39909] mt-10 cursor-pointer'
                    variant="rato"
                    size={"lg"}
                    disabled={isLoading || email === '' || password === ''}
                >
                    {isLoading ? 'Deleting...' : 'Delete Account'}
                </Button>
            </div>
        </div>
    );
};

export default DeleteAccount;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface UserData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  phone: string;
  address: string;
}

interface RegisterResponse {
  success: boolean;
  message: string;
  user?: {
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    address: string;
  };
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface LoginResponse {
  success: boolean;
  token: string;
  user: {
    id: string;
    email: string;
    // ... other user properties
  };
}

interface DeleteResponse {
  success: boolean;
  message: string;
}

// API slice
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.ratokunbo.com/api/v1/' }),
  endpoints: (builder) => ({
    registerUser: builder.mutation<RegisterResponse, UserData>({
      query: (userData) => ({
        url: 'users/register',
        method: 'POST',
        body: userData,
      }),
      transformErrorResponse: (
        response: { status: number, data: any },
        meta,
        arg
      ) => response.data
    }),
    login: builder.mutation<LoginResponse, LoginCredentials>({
      query: (credentials) => ({
        url: 'users/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    deleteUser: builder.mutation<DeleteResponse, { email: string; password: string }>({
      query: ({ email, password }) => ({
        url: 'users/delete',
        method: 'DELETE',
        body: { email, password },
      }),
    }),
  }),
});

export const { useRegisterUserMutation, useLoginMutation, useDeleteUserMutation } = apiSlice;
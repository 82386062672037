import React from 'react';
import Rato from "src/assets/rato-letter.png";
import Apple from "src/assets/apple-img-sm.png";
import Android from "src/assets/android-img-sm.png";
import Twitter from "src/assets/twitter-img-sm.png";
import Facebook from "src/assets/facebook-img-sm.png";
import Instagram from "src/assets/instagram-img-sm.png";
import { Copyright } from 'lucide-react';


const Footer = () => {
  return (
    <>
        <div className='flex justify-around bottom-0'>
        <div>
            <div className='flex'>
            <img src={Rato} />
            <p className='ml-2 font-semibold'>There's more shopping <br/>in the app</p>
            </div>
            <div className='flex my-6 gap-x-4'>
                <div className='px-4 py-3 bg-[#F3F3F3] flex rounded-2xl items-center justify-center'>
                <img src={Apple} />
                <p className='font-semibold ml-3'>Iphone</p>
                </div>
                <div className='px-4 py-3 bg-[#F3F3F3] flex rounded-2xl items-center justify-center'>
                <img src={Android} />
                <p className='font-semibold ml-3'>Android</p>
                </div>
            </div>
        </div>
        <div className='flex flex-col gap-y-3'>
            <h3 className='font-semibold'>Buy</h3>
            <p>Registration</p>
            <p>Faq</p>
            <p>Stores</p>
        </div>
        <div className='flex flex-col gap-y-3'>
            <h3 className='font-semibold'>Sell</h3>
            <p>Start selling</p>
            <p>Learn to sell</p>
        </div>
        <div className='flex flex-col gap-y-3'>
            <h3 className='font-semibold'>About Ratokunbo</h3>
            <p>Company info</p>
            <p>News</p>
            <p>Investors</p>
            <p>Careers</p>
            <p>Policy</p>
        </div>
        <div className='flex flex-col gap-y-3'>
            <h3 className='font-semibold'>Help & Contact</h3>
            <p>Seller information centre</p>
            <p>Contact us</p>
            <p>Investors</p>
            <p>Careers</p>
            <p>Policy</p>
        </div>
        </div>
        <hr className='my-16 bg-[#E5E5E5]'/>
        <div className='flex w-full mb-24'>
         <div className='flex gap-x-3'>
          <img src={Instagram} className="cursor-pointer"/>
          <img src={Facebook} className="cursor-pointer"/>
          <img src={Twitter} className="cursor-pointer"/>
         </div>
         <p className='m-auto'>Copyright © 1995-2024 eBay Inc. All Rights Reserved. </p>
        </div>
    </>
  )
}

export default Footer;

import {
  Routes,
  Route
} from "react-router-dom";
import LandingPage from './pages/landingPage';
import PrivacyPage from './pages/PrivacyPage';
import SignUp from './pages/signUp';
import Login from "./pages/login";
import ForgetPassword from './pages/forgetPassword';
import DeleteAccount from './pages/deleteAccount';
import Home from "./pages/home";
import { Toaster } from "./components/ui/toaster";
import { ToastContainer } from 'react-toastify';


function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="privacy-page" element={<PrivacyPage />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="login" element={<Login />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="delete-account" element={<DeleteAccount />} />
      </Routes>
      
    </> 
  );
}

export default App;

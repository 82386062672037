import React from 'react';
import Logo from "src/assets/logo.svg";
import Shop from "src/assets/shop.png";
import { ChevronDown, ShoppingCart, Bell, CircleUserRound } from 'lucide-react';
import { Input } from '../components/ui/input';
import Product from 'src/components/Product';
import { useNavigate } from "react-router-dom";
import { productList, products } from 'src/data/product'; 
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../components/ui/carousel";
import Footer from './footer';

const productss = [
  {
    "richDescription": "The Hoover Compact Single-Door Upright Freezer is the perfect addition to any home, office, or dorm room, offering reliable and efficient storage for your frozen goods. This sleek, black freezer combines functionality with a modern design, ensuring it complements any space.\n\nKey Features:\n\nCompact Design: The freezer's compact size makes it ideal for small spaces, such as apartments, offices, or dorm rooms.\nSpacious Interior: Despite its compact exterior, the freezer boasts a generous interior space with four transparent drawers, allowing for easy organization and access to your frozen items.\nEnergy Efficient: Equipped with a high-efficiency compressor, this freezer ensures your food stays frozen while keeping energy consumption low.\nDurable Build: The robust build quality and scratch-resistant exterior ensure longevity, even in busy households or workplaces.\nEasy Maintenance: The removable drawers and simple interior design make cleaning a breeze, ensuring your freezer stays hygienic and odor-free.\nEnvironmentally Friendly: Uses R-600a refrigerant, which is eco-friendly and helps reduce your carbon footprint.\nTechnical Specifications:\n\nDimensions: 24.5 x 22 x 33 inches (H x W x D)\nColor: Black\nCapacity: 3.5 cubic feet\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\n",
    "image": "",
    "images": [
        "https://ratokunbo-products-images.s3.amazonaws.com/a586632c-76a4-400a-93e0-67645891c000__MG_7384.jpg",
        "https://ratokunbo-products-images.s3.amazonaws.com/cb4e8442-9e06-401c-b6f3-4ec8f8d381fc__MG_7388.jpg",
        "https://ratokunbo-products-images.s3.amazonaws.com/a2f4b308-f891-46a6-84b4-90ddcefa9edf__MG_7392.jpg"
    ],
    "brand": "Hoover",
    "price": 158250,
    "realPrice": 0,
    "numReviews": 10,
    "isFeatured": false,
    "status": "available",
    "_id": "666aa30ca99dde00296b11fb",
    "rating": 2,
    "name": "Hoover Compact Single-Door Upright Freezer",
    "description": "Backed by Hoover’s reliable brand reputation, this compact upright freezer is an excellent choice for those in need of efficient and dependable freezing capabilities in a stylish, space-saving design.\n\n\n\n\n\n",
    "category": {
        "_id": "5f15d5b2cb4a6642bddc0fe7",
        "name": "Home",
        "__v": 0,
        "color": "#E2E1F0",
        "icon": "home",
        "id": "5f15d5b2cb4a6642bddc0fe7"
    },
    "countInStock": 1,
    "owner": "666b0981aaaed6424d1ec13d",
    "dateCreated": "2024-06-13T07:43:08.524Z",
    "__v": 7,
    "id": "666aa30ca99dde00296b11fb"
},
{
    "richDescription": "Key Features:\n\nStylish Retro Design: The eye-catching red finish and chrome handles bring a nostalgic charm to your kitchen decor, making this refrigerator a stylish addition to any home.\nSpacious Storage: With a total capacity of 6.5 cubic feet, this refrigerator-freezer combo offers plenty of space to store your groceries. The top-mount freezer provides easy access to frozen foods, while the main refrigerator compartment includes adjustable shelves, door bins, and a spacious crisper drawer.\nEnergy Efficient: Designed with energy efficiency in mind, this refrigerator helps you save on electricity bills while keeping your food fresh and your frozen items well-preserved.\nDurable Construction: Built with high-quality materials, the Montpellier Retro Style Refrigerator-Freezer is made to last, ensuring reliable performance for years to come.\nEasy Maintenance: The removable shelves and door bins make cleaning and organizing a breeze, helping you keep your fridge in pristine condition with minimal effort.\nTechnical Specifications:\n\nDimensions: 65 x 24 x 27 inches (H x W x D)\nColor: Red\nCapacity: 6.5 cubic feet (4.5 cu. ft. refrigerator, 2.0 cu. ft. freezer)\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz",
    "image": "",
    "images": [
        "https://ratokunbo-products-images.s3.amazonaws.com/9e23d2d5-1236-46fa-bd70-cccb82314200__MG_7396.jpg",
        "https://ratokunbo-products-images.s3.amazonaws.com/a188a7ff-1b56-4169-9666-bf3b8b5d1143__MG_7399.jpg",
        "https://ratokunbo-products-images.s3.amazonaws.com/c4c8f545-3faf-408c-abd9-72624ebc8430__MG_7401.jpg"
    ],
    "brand": "Montpellier",
    "price": 211000,
    "realPrice": 0,
    "ratings": [],
    "numReviews": 10,
    "isFeatured": false,
    "status": "available",
    "_id": "666aa45ca99dde00296b120f",
    "rating": 0,
    "name": "Montpellier Retro Style Top-Mount Refrigerator-Freezer - 6.5 Cu. Ft., Red",
    "description": "Add a touch of retro flair to your kitchen with the Montpellier Retro Style Top-Mount Refrigerator-Freezer. Combining vintage aesthetics with modern functionality, this vibrant red appliance not only makes a statement but also provides ample storage for your fresh and frozen foods.",
    "category": {
        "_id": "5f15d5b2cb4a6642bddc0fe7",
        "name": "Home",
        "__v": 0,
        "color": "#E2E1F0",
        "icon": "home",
        "id": "5f15d5b2cb4a6642bddc0fe7"
    },
    "countInStock": 1,
    "owner": "666b0981aaaed6424d1ec13d",
    "dateCreated": "2024-06-13T07:48:44.322Z",
    "__v": 0,
    "id": "666aa45ca99dde00296b120f"
},
{
    "richDescription": "Key Features:\n\nGenerous Capacity: Offering 7.0 cubic feet of total storage space, this refrigerator-freezer provides ample room for all your fresh and frozen groceries.\nTop-Mount Freezer: The convenient top-mount freezer allows for easy access to frozen foods and includes adjustable shelves to accommodate various items.\nEnergy Efficient: Designed to minimize energy consumption, this refrigerator helps you save on electricity bills while maintaining optimal cooling performance.\nDurable Construction: Built with high-quality materials, the Hoover Classic refrigerator is designed to withstand daily use, ensuring long-lasting reliability.\nEasy to Clean: The smooth surfaces and removable shelves and bins make cleaning this refrigerator a hassle-free task, helping you maintain a hygienic kitchen environment.\nSimple and Practical Design: The classic white finish and straightforward design make this refrigerator a perfect fit for any kitchen decor, providing both style and functionality.\nTechnical Specifications:\n\nDimensions: 68 x 24 x 28 inches (H x W x D)\nColor: White\nCapacity: 7.0 cubic feet (4.5 cu. ft. refrigerator, 2.5 cu. ft. freezer)\nEnergy Rating: A+\nRefrigerant: R-600a\nVoltage: 220-240V\nFrequency: 50Hz\nElevate your kitchen's efficiency and style with the Hoover Classic Top-Mount Refrigerator-Freezer, a dependable choice for keeping your food fresh and your kitchen organized.",
    "image": "",
    "images": [
        "https://ratokunbo-products-images.s3.amazonaws.com/1749f570-c2bc-40ef-97a6-1ce2e6ecdef4__MG_7403.jpg"
    ],
    "brand": "Hoover",
    "price": 263750,
    "realPrice": 0,
    "ratings": [],
    "numReviews": 10,
    "isFeatured": false,
    "status": "available",
    "_id": "666aa55aa99dde00296b1219",
    "rating": 0,
    "name": "Hoover Classic Top-Mount Refrigerator-Freezer - 7.0 Cu. Ft., White",
    "description": "The Hoover Classic Top-Mount Refrigerator-Freezer is a versatile and spacious appliance designed to meet the demands of modern kitchens. With a clean white finish and practical design, this refrigerator-freezer combo ensures your food stays fresh and organized.",
    "category": {
        "_id": "5f15d5b2cb4a6642bddc0fe7",
        "name": "Home",
        "__v": 0,
        "color": "#E2E1F0",
        "icon": "home",
        "id": "5f15d5b2cb4a6642bddc0fe7"
    },
    "countInStock": 1,
    "owner": "666b0981aaaed6424d1ec13d",
    "dateCreated": "2024-06-13T07:52:58.123Z",
    "__v": 0,
    "id": "666aa55aa99dde00296b1219"
},
]

const rowTwo = productList.slice(0,10)
const rowOne = productList.slice(0,5)


const menuList = ['Deals', 'Cell Phone', 'Office supplies', 'Furniture', 'Appliances', 'Electronics', 'Home Living',
'Wearable', 'Health & Fitness', 'Watches', "TV's", 'Car Tech', 'Computers'];




const Home = () => {
  const navigate = useNavigate();
  // const userSession = JSON.parse(localStorage.getItem('userSession') || '{}');

  const isLoggenIn = localStorage.getItem('firstname')
  // console.log(firstname, "this is me")

  return (
    <div className='flex flex-col min-h-screen'>
    <div className='w-full px-16'>
      <div className=' flex items-center gap-4 '>
        <img src={Logo} alt="Logo" className="w-64 h-28" /> 
        <p className='flex items-center font-semibold'>Shop by <br/>Category<span><ChevronDown /></span></p>
        <Input type="text" name="address" placeholder='Search for anything' className='bg-[#F7F7F7] py-6 rounded-lg'/>
        <div className='flex w-[20%] justify-between'>
          <div className='flex gap-4'>
            <p><ShoppingCart /></p>
            <p><Bell /></p>
          </div>
        </div>
         {!isLoggenIn ? 
         <div className='flex'>
          <div className="bg-white py-2 px-4 rounded-md hover:bg-orange-400 hover:grow 
          hover:rounded-2xl font-semibold cursor-pointer whitespace-nowrap" onClick={()=> navigate('/login')}>Log in</div>
          <div className="bg-white py-2 px-4 rounded-md hover:bg-orange-400 active:bg-orange-400
           font-semibold hover:grow hover:rounded-2xl cursor-pointer whitespace-nowrap" onClick={()=> navigate('/sign-up')}>Sign up</div>
          </div> :
          <div className='flex items-center p-2'>
            <div className='flex gap-2 items-center'>
            <p><CircleUserRound /></p>
            <p className='font-semibold text-sm'>Hi,{isLoggenIn}</p>
            </div>
            <p className='text-xs'><ChevronDown /></p>
          </div>}
     </div>
      <div className='flex font-semibold cursor-pointer gap-4 justify-center my-3'>
        {menuList.map((list, i) => (
          <p key={i}>{list}</p>
        ))}
      </div>
      <div className='mt-8'>
       <img src={Shop} alt="Logo" className="w-full h-64 " />
      </div>
      <div className=''>
        <Product 
        category='New Arrival'
        products={productList} 
        isCarosel
        />
        <Product 
        category='Shop by Category'
        products={products} 
        isCategory
        />
        <Product 
        category='Best Selling'
        products={rowOne} 
        actionText='See all'
        isRowTwo
        />
      <div className='my-8'>
       <img src={Shop} alt="Logo" className="w-full h-64 " />
      </div>
      <Product 
        category='Trending'
        products={rowTwo} 
        actionText='See all'
        isRowTwo
        />
      </div>
    </div>
     <div className='px-8 pt-3 w-full border-t-2 mt-12 bottom-0'>
       <Footer />
     </div> 
    </div>
  ) 
}

export default Home;